<template lang="pug">
.dashboard-wrapper
  b-row
    b-col
      b-card(no-body, class="pt-2 px-2")
        filters(
          :customFiltersShow="true"
          :locationFilterShow="true",
          :dateRangeFilterShow="true",
          :locationDefaultValue="true",
          @changeFilter="updateFilter",
          @locations="updateLocations"
        )

  b-row(v-if="isLoading")
    b-col
      b-card(class="py-5")
        loading(
          :active="true" 
          :is-full-page="false"
          :color="colors.primary"
        )

  div(v-else)
    b-row(v-if="!opinionsTotal")
      b-col
        b-card(class="text-center")
          h5(class="mb-0")
            | {{ $t('message.no_records') }}

    div(v-else)
      b-row
        b-col(sm="3")
          statistic-card-vertical-index(:statistic="opinionsIndex")
        b-col(sm="3")
          statistic-card-vertical-positive(:statistic="opinionsPositive")
        b-col(sm="3")
          statistic-card-vertical-neutral(:statistic="opinionsNeutral")

        b-col(sm="3")
          statistic-card-vertical-negative(:statistic="opinionsNegative")

      b-row
        b-col(cols="12")
          bar-chart-opinions(
            v-if="opinionsPerDay.labels && opinionsPerDay.labels.length",
            :happiness-data="opinionsPerDay"
          )

      b-card
        b-table-simple(hover, small, caption-top, responsive)
          b-thead(head-variant="light")
            b-tr
              th {{ $t('opinions.date') }}
              th {{ $t('opinions.location') }}
              th {{ $t('message.tableHeader.feedback') }}
            b-tr(v-for="(opinion, index) in opinions", :key="index")
              b-td {{ opinion.dateString }}
              b-td {{ opinion.location }}
              b-td(:class="`text-${opinion.textColor}`") {{ $t(`label.${opinion.feedback}`) }}

      b-button(class="mr-4 mb-1", :disabled="!opinions.length")
        json-excel(:data="opinions", :fields="excelFields")
          | {{ $t('download_xlsx') }}
</template>
  
<script>
import { onMounted, ref, computed } from "@vue/composition-api/dist/vue-composition-api"
import store from "@/store"
import useNotifications from "@/composables/useNotifications"
import { BTableSimple, BThead, BTr, BTd, BCard } from "bootstrap-vue"
import JsonExcel from "vue-json-excel"
import StatisticCardVerticalPositive from './charts/StatisticCardVerticalPositive.vue'
import StatisticCardVerticalNeutral from './charts/StatisticCardVerticalNeutral.vue'
import StatisticCardVerticalNegative from './charts/StatisticCardVerticalNegative.vue'
import StatisticCardVerticalIndex from './charts/StatisticCardVerticalIndex.vue'
import BarChartOpinions from './charts/ChartjsBarChartOpinions.vue'
import Filters from "@/views/organization/Filters.vue";
import i18n from '@/libs/i18n'
import Ripple from 'vue-ripple-directive'
import useCommonDashboards from '@/views/habit/useCommonDashboards'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import { colors } from '@/constants'
import realmConnection from '@/views/habit/realm'

export default {
  components: {
    BTableSimple,
    BThead,
    BTr,
    BTd,
    BCard,
    Filters,
    JsonExcel,
    StatisticCardVerticalPositive,
    StatisticCardVerticalNeutral,
    StatisticCardVerticalNegative,
    StatisticCardVerticalIndex,
    BarChartOpinions,
    Loading,
  },
  directives: {
    Ripple,
  },
  setup() {
    const userData = store.state?.userStore?.userData;
    const userRole = userData.role;
    const client_id = userRole !== "admin" ? userData.client.$oid : null;
    const userLocations = userData.locations?.length ? userData.locations : null;
    const { getDatesFromRange, happinessIndex } = useCommonDashboards()
    const { showErrorMessage } = useNotifications();
    const { getItemsWithAggregate, ObjectId, updateItem, updateItems } = realmConnection()
    const opinions = ref([])
    const opinionsTotal = ref(0)
    const opinionsIndex = ref(0)
    const opinionsPerDay = ref({})
    const opinionsPositive = ref(0)
    const opinionsNeutral = ref(0)
    const opinionsNegative = ref(0)
    const locations = localStorage.locations ? ref(JSON.parse(localStorage.locations)) : ref([])
    const locationFilter = ref(userLocations ? userLocations.map(e => e.value) : []);
    const now = new Date()
    const currentMonth = now.getMonth()
    const currentYear = now.getFullYear()
    const lastDayOfMonthDate = new Date(currentYear, currentMonth + 1, 0)
    const lastDayOfMonthNumber = lastDayOfMonthDate.getDate()
    const dateRangeFilter = ref(`01-${currentMonth + 1}-${currentYear} to ${lastDayOfMonthNumber}-${currentMonth + 1}-${currentYear}`)
    const isLoading = ref(true)
    const { default_language } = JSON.parse(localStorage.getItem('clientData') || '{}')

    const excelFields = ref({
      [i18n.t('opinions.date')]: "dateString",
      [i18n.t('opinions.location')]: "location",
      [i18n.t('message.tableHeader.feedback')]: "feedbackTranslate",
      ['ID']: "id",
    })

    const listOpinions = async () => {
      isLoading.value = true
      const [startFilter, endFilter] = getDatesFromRange(dateRangeFilter.value)

      try {
        const query = {
          client_id: ObjectId(client_id),
          deleted: { $ne: true },
          date: { $gte: startFilter, $lt: endFilter },
        }

        if (locationFilter.value.length > 0) {
          query.location = { $in: locationFilter.value.map(e => ObjectId(e)) }
        }

        const pipeline = [
          { $match: query },
          {
            $lookup: {
              from: 'location',
              localField: 'location',
              foreignField: '_id',
              pipeline: [ { $project: { location: 1 } }, { $addFields: { _id: { $toString: "$_id" } } } ],
              as: 'location'
            }
          },
          { $addFields: { _id: { $toString: "$_id" }, location: { $arrayElemAt: ["$location", 0] } } },
          { $sort: { date: 1 } },
        ]
      
        const items = await getItemsWithAggregate({ collection: 'customer_opinion', pipeline })

        parseOpinionsData(items, startFilter, endFilter)
      } catch (error) {
        console.log(error)
        showErrorMessage(i18n.t('message.err_opinions_list'))
      } finally {
        isLoading.value = false
      }
    }

    const parseOpinionsData = (items, startFilter, endFilter) => {
      let opinionsFeedbackData = {}
      let dynamicDate = startFilter

      // Change the dates so that it matches the UTC time zone
      const timezoneOffsetHours = dynamicDate.getTimezoneOffset() / 60
      dynamicDate.setHours(dynamicDate.getHours() + timezoneOffsetHours)
      endFilter.setHours(endFilter.getHours() + timezoneOffsetHours)

      while (dynamicDate < endFilter) {
        const dateToAdd = dynamicDate.toLocaleDateString(`${default_language || 'en'}-US`)
        opinionsFeedbackData[dateToAdd] = { positive: 0, neutral: 0, negative: 0 }
        dynamicDate = new Date(dynamicDate.getFullYear(), dynamicDate.getMonth(), dynamicDate.getDate() + 1)
      }

      let opinionsData = []
      let feedbackData = { positive: 0, neutral: 0, negative: 0 }

      for (const opinion of items) {
        const date = opinion.date
        date.setHours(date.getHours() + timezoneOffsetHours)
        const dateString = date.toLocaleDateString(`${default_language || 'en'}-US`)

        const feedbackMap = {
          positive: 'success',
          neutral: 'warning',
          negative: 'danger',
        }

        const feedbackType = opinion.feedback
        const textColor = feedbackMap[feedbackType] || 'warning'
        const feedbackTranslate = computed(() => i18n.t(`label.${feedbackType}`)).value

        opinionsData.push({
          date,
          dateString,
          location: opinion.location?.location,
          feedback: feedbackType,
          feedbackTranslate,
          textColor,
          id: opinion._id,
        })

        feedbackData[feedbackType] += 1

        // Data for bar chart
        if (!opinionsFeedbackData[dateString]) {
          opinionsFeedbackData[dateString] = { positive: 0, neutral: 0, negative: 0 }
        }
        opinionsFeedbackData[dateString][feedbackType] += 1
      }

      // Global values
      opinions.value = opinionsData
      opinionsTotal.value = opinionsData.length
      opinionsPositive.value = feedbackData.positive
      opinionsNeutral.value = feedbackData.neutral
      opinionsNegative.value = feedbackData.negative
      opinionsIndex.value = (!feedbackData.positive && !feedbackData.neutral && !feedbackData.negative)
        ? "-"
        : `${happinessIndex(feedbackData.positive, feedbackData.neutral, feedbackData.negative)}%`

      // Bar chart
      let opinionsLabels = Object.keys(opinionsFeedbackData)
      let opinionsValues = []

      for (const date in opinionsFeedbackData){
        opinionsValues.push([opinionsFeedbackData[date].positive, opinionsFeedbackData[date].neutral, opinionsFeedbackData[date].negative])
      }

      opinionsPerDay.value = {
        labels: opinionsLabels,
        data: opinionsValues
      }
    }
    
    const updateFilter = (data) => {
      locationFilter.value = data.locationFilter
      dateRangeFilter.value = data.dateRangeFilter      
      listOpinions()
    }

    const updateLocations = (data) => {
      if (!locations.value.length) {
        locations.value = data
        listOpinions()
      }
    }

    onMounted(() => {
      listOpinions()
    })

    return {
      opinions,
      opinionsTotal,
      opinionsIndex,
      updateFilter,
      updateLocations,
      excelFields,
      isLoading,
      opinionsPerDay,
      opinionsPositive,
      opinionsNeutral,
      opinionsNegative,
      colors
    }
  },
};
</script>